// frontend/src/components/common/Navbar.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import LoginButton from '../../containers/components/LoginButton';
import LogoutButton from '../../containers/components/LogoutButton';
import { getSubscriptionStatus } from '../../redux/actions/authActions';
import defaultPicture from '../../assets/icons/navbarmrferret.png';

import './NavBar.css';

function Navbar() {
  const dispatch = useDispatch();
  const { isAuthenticated, isLoading } = useAuth0(); // Use Auth0 for auth status
  const isPaidSubscriber = useSelector((state) => state.auth.isPaidSubscriber);
  const navigate = useNavigate();
  
  
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getSubscriptionStatus());
    }
  }, [isAuthenticated, dispatch]);


  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
      <a className="navbar-logo" href="/">
          {
            <img
              src={defaultPicture}
              className="navbar-mrFerret"
              alt="Navbar Logo"
            />
          }

        </a>

      <a className="navbar-brand" href="/">
          StreamChatAndy.com
      </a>
      <div className="navbar-beta">
          BETA
      </div>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">

            {/* Buttons that are always visible regardless of authentication */}
            <li className="nav-item">
              <button className="btn btn-link nav-link" onClick={() => navigate('/about')}>
                About
              </button>
            </li>
            <li className="nav-item">
              <button className="btn btn-link nav-link" onClick={() => navigate('/create')}>
                Create!
              </button>
            </li>
            <li className="nav-item">
            <div className="tooltip-wrapper">
              <button
                className={`btn btn-link nav-link ${!isPaidSubscriber ? 'disabled' : ''}`}
                onClick={() => isPaidSubscriber && navigate('/live')}
                disabled={!isPaidSubscriber}
              >
                LIVE!
              </button>
              {!isPaidSubscriber && (
                <span className="tooltip-text">Login & Subscribe</span>
              )}
            </div>
          </li>

            {/* Additional buttons when not authenticated */}
            {!isAuthenticated && !isLoading && (
              <>
                <li className="nav-item">
                  <LoginButton /> {/* Render LoginButton */}
                </li>
              </>
            )}

            {/* Additional buttons when authenticated */}
            {isAuthenticated && !isLoading && (
              <>
                <li className="nav-item">
                  <button className="btn btn-link nav-link" onClick={() => navigate('/account')}>
                    Account
                  </button>
                </li>
                <li className="nav-item">
                  <LogoutButton /> {/* Render LogoutButton */}
                </li>
              </>
            )}

            {/* Loading state */}
            {isLoading && (
              <li className="nav-item">
                <span className="nav-link">Loading...</span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}

// Wrap Navbar in React.memo to prevent unnecessary re-renders
export default React.memo(Navbar);